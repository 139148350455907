@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
.titillium-web-extralight {
  font-family: "Titillium Web", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.titillium-web-light {
  font-family: "Titillium Web", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.titillium-web-regular {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.titillium-web-semibold {
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.titillium-web-bold {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.titillium-web-black {
  font-family: "Titillium Web", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.titillium-web-extralight-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.titillium-web-light-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.titillium-web-regular-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.titillium-web-semibold-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.titillium-web-bold-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  font-style: italic;
}
body {
  margin: 0;
}

.login_box {
  background-image: url(Assets/images/login_bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.login_cont {
  background-color: #f3f7f8;
  padding: 40px 30px;
  width: 380px;
  box-shadow: inset 0px 0px 6px 1px rgba(0, 0, 0, 0.5);
}

.login_box .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.pdf-content-page {
  position: relative;
  display: flex;
  flex-grow: 1;
  height: 60vh;
}
.pdf-content-page__content {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}
.pdf-content-page__content iframe {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.pdf-content-page__not-found {
  padding: 10px;
}
